import Vue from "vue";

/*TITLE*/
document.title = "ALCALÁ 266 | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "ALCALÁ 266";
Vue.prototype.$subtitle = "Madrid";

/*INTRO*/
Vue.prototype.$promoter = "ALCALÁ 266";
Vue.prototype.$introSubtitle = "Madrid";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "0-livingroom--20221124091505.jpeg";
Vue.prototype.$image_kitchen = "0-kitchen--20221124091505.jpeg";
Vue.prototype.$image_bath1 = "0-bathroom1--20221124091505.jpeg";
Vue.prototype.$image_bath2 = "";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "0-livingroom--20221124091505.jpeg",
  },
  {
    src: "1-livingroom--20221124091505.jpeg",
  },
  {
    src: "2-livingroom--20221124091505.jpeg",
  },
  {
    src: "3-livingroom--20221124091505.jpeg",
  },
  {
    src: "4-livingroom--20221124091505.jpeg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "0-kitchen--20221124091505.jpeg",
  },
  {
    src: "1-kitchen--20221124091505.jpeg",
  },
  {
    src: "2-kitchen--20221124091505.jpeg",
  },
  {
    src: "3-kitchen--20221124091505.jpeg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "0-bathroom1--20221124091505.jpeg",
  },
  {
    src: "1-bathroom1--20221124091505.jpeg",
  },
  {
    src: "2-bathroom1--20221124091505.jpeg",
  },
  {
    src: "3-bathroom1--20221124091505.jpeg",
  },
  {
    src: "4-bathroom1--20221124091505.jpeg",
  },
  {
    src: "5-bathroom1--20221124091505.jpeg",
  },
  {
    src: "6-bathroom1--20221124091505.jpeg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/Z2hY72olF9M";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20alcalá%20266%20y%20marcelino%20alvarez%203%20madrid",
    text: "Calle Alcalá 266 y Marcelino Alvarez 3 Madrid",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=avenida%20de%20europa%20nº%2031%20berna%20nº%208%2028224%20pozuelo%20de%20alarcón%20madrid%20",
    text: "Avenida de Europa nº 31- Berna nº 8, 28224 Pozuelo de Alarcón (Madrid)",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:(+34)915912058",
    text: "(+34)915912058",
  },
  {
    icon: "mdi-email",
    link: "mailto:administracion@inercia24.com",
    text: "administracion@inercia24.com",
  },
];
